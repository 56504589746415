<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">订单管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">退款申请列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:10px;">
            <div title="订单编号" class="searchboxItem ci-full">
              <span class="itemLabel">订单编号:</span>
              <el-input v-model="searchForm.orderSn" type="text" size="small" clearable placeholder="请输入课程名称">
              </el-input>
            </div>
            <div title="退款单号" class="searchboxItem ci-full">
              <span class="itemLabel">退款单号:</span>
              <el-input v-model="searchForm.refundSn" type="text" size="small" clearable placeholder="请输入课程名称">
              </el-input>
            </div>
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input v-model="searchForm.productName" type="text" size="small" clearable placeholder="请输入课程名称">
              </el-input>
            </div>
            <div title="退款申请日期" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 150px;">退款申请日期:</span>
              <el-date-picker clearable size="small" v-model="searchForm.timeData" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div title="审核状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 130px;">审核状态:</span>
              <el-select v-model="searchForm.refundStatus" placeholder="请选择审核状态" size="small" clearable>
                <el-option v-for="item in refundStatusList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div class="btnBox">
              <el-button class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" width="100px" :index="indexMethod" />
              <el-table-column label="订单编号" align="left" prop="orderSn" show-overflow-tooltip width="160" />
              <el-table-column label="退款单号" align="left" prop="refundSn" show-overflow-tooltip width="160" />
              <el-table-column label="课程名称" align="left" prop="productName" show-overflow-tooltip width="200" />
              <el-table-column label="订单金额" align="left" prop="orderMoney" show-overflow-tooltip width="100" />
              <el-table-column label="退款金额" align="left" prop="refundAmount" show-overflow-tooltip width="100" />
              <el-table-column label="退款人数" align="left" prop="refundCnt" show-overflow-tooltip width="100" />
              <el-table-column label="审核状态" align="left" prop="refundStatus" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.refundStatus == 0">新建</span>
                  <span v-else-if="scope.row.refundStatus == 1">成功</span>
                  <span v-else-if="scope.row.refundStatus == 2">失败</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column label="驳回理由" align="left" prop="refundRemarks" show-overflow-tooltip min-width="150" />
              <el-table-column label="申请退款时间" align="left" prop="refundTime" show-overflow-tooltip width="140" />
              <el-table-column label="操作" align="center" width="100px" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="refundReviewOpen(scope.row)" :disabled="scope.row.refundStatus != 0">审核</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 退款审核 -->
    <el-dialog title="退款审核" :visible.sync="refundReview.dialogVisible" width="600px" @close="refundReviewClose">
      <el-form :model="refundReview.formData_1" :rules="refundReview.rules_1" ref="formData_1" size="small"
        label-width="100px">
        <el-form-item label="订单编号">
          <el-input v-model="refundReview.formData_1.orderSn" placeholder="请输入订单编号" disabled size="small" />
        </el-form-item>
        <el-form-item label="课程名称">
          <el-input v-model="refundReview.formData_1.productName" placeholder="请输入课程名称" disabled size="small" />
        </el-form-item>
        <el-form-item label="订单金额">
          <el-input v-model="refundReview.formData_1.orderMoney" placeholder="请输入退款金额" disabled size="small" />
        </el-form-item>
        <el-form-item label="退款人数">
          <el-input v-model="refundReview.formData_1.refundCnt" placeholder="请输入退款人数" disabled size="small" />
        </el-form-item>
        <el-form-item label="退款金额">
          <el-input v-model="refundReview.formData_1.refundAmount" placeholder="请输入退款金额" disabled size="small" />
        </el-form-item>
        <el-form-item label="驳回原因">
          <el-input type="textarea" placeholder="请输入驳回原因" v-model="refundReview.formData_1.refundRemarks" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refundReviewOpenNo">驳 回</el-button>
        <el-button @click="refundReviewOpenOk" class="bgc-bv">通 过</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "courseCenterInfo_refundApplicationList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      searchForm: {
        orderSn: "", // 订单编号
        refundSn: "", // 退款单号
        productName: "", // 课程名称
        timeData: "", // 退款申请日期
        refundStatus: "", // 审核状态
      },
      // 审核状态 - 下拉数据
      refundStatusList: [
        {
          value: "0",
          label: "新建"
        },
        {
          value: "1",
          label: "成功"
        },
        {
          value: "2",
          label: "失败"
        }
      ],
      // 退款审核 - 弹框数据
      refundReview: {
        dialogVisible: false, //弹框状态
        // 表单数据
        formData_1: {
          refundRemarks: "", // 退款原因
        },
        // 表单数据 - 校验
        rules_1: {},
      },
    };
  },
  computed: {},
  created() { },
  methods: {
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.searchForm
      };
      if (this.searchForm.timeData) {
        params.refundBeginTime = this.searchForm.timeData[0];
        params.refundEndTime = this.searchForm.timeData[1];
      }
      this.doFetch({
        url: "/order/drp-order-refund/queryRefundListPay",
        params,
        pageNum,
      }, true, 2);
    },
    // 退款审核 - 打开弹框
    refundReviewOpen(row) {
      this.refundReview.formData_1 = { ...this.refundReview.formData_1, ...row }
      this.refundReview.dialogVisible = true;
    },
    // 退款审核 - 关闭弹框
    refundReviewClose() {
      this.refundReview = this.$options.data().refundReview;
      this.$nextTick(() => {
        this.$refs['formData_1'].resetFields();
      })
    },
    // 退款审核 - 打开弹框 - 驳回
    refundReviewOpenNo() {
      console.log(this.refundReview.formData_1.refundRemarks)
      if (!this.refundReview.formData_1.refundRemarks) {
        this.$message({
          type: "warning",
          message: "请输入驳回原因",
        });
        return
      }
      this.$refs['formData_1'].validate((valid) => {
        if (valid) {
          this.$post("/order/drp-order-refund/disAgree", {
            refundId: this.refundReview.formData_1.refundId,
            refundRemarks: this.refundReview.formData_1.refundRemarks,
          }, 3000, true, 2).then(res => {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getData(this.pageNum);
              this.refundReviewClose();
            }
          );
        }
      });
    },
    // 退款审核 - 打开弹框 - 通过
    refundReviewOpenOk() {
      this.$refs['formData_1'].validate((valid) => {
        if (valid) {
          this.$post("/order/drp-order-refund/agree", {
            refundId: this.refundReview.formData_1.refundId
          }, 3000, true, 2).then(res => {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.getData(this.pageNum);
            this.refundReviewClose();
          }
          );
        }
      });
    },
  },
};
</script>
<style lang="less" scoped></style>